import React from 'react'
import { Box, Alert } from 'fannypack'
import RegisterScenario from '../RegisterScenario'

const RegisterError = () => {
  return (
    <RegisterScenario title={'Registration Error'}>
      <Box marginBottom="major-4">
        <Alert hasTint border="0" type="danger" hasIcon>
          An error occurred while completing your registration. Please try again
          and then contact your Activity Organiser if you continue to receive an
          error.
        </Alert>
      </Box>
    </RegisterScenario>
  )
}
export default RegisterError
