import { createContext, useContext } from 'react'
import { TProduct, TProject, TSite, TBooking, TOrder } from '../types'

type Context = {
  project: TProject
  products: TProduct[]
  site: TSite
  bookings: TBooking[]
  setShowError: Function
  orders: TOrder[]
}
const MerchantContext = createContext<Partial<Context>>({})

export const useMerchantContext = () => useContext(MerchantContext)

export default MerchantContext
