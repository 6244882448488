import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { ThemeProvider, styled } from 'fannypack'
import Merchant from './containers/Merchant'
import Login from './containers/Login'
import Register from './containers/register/Register'
import RegisterWithStripeSuccess from './containers/register/scenarios/RegisterWithStripeSuccess'
import RegisterError from './containers/register/scenarios/RegisterError'
import Error from './containers/Error'
import Page from './containers/Page'
import { FANNYPACK_THEME, ROUTES } from './constants'
import { client } from './sdk/client'
import RegisterExistingUser from './containers/register/scenarios/RegisterExistingUser'
import RegisterFromBooking from './containers/register/RegisterFromBooking'
import RegisterDeclined from './containers/register/RegisterDeclined'
import RegisterSuccess from './containers/register/scenarios/RegisterSuccess'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV
  })
}

const Container = styled.div`
  height: 100%;
`

const App = () => {
  return (
    // @ts-ignore
    <ThemeProvider theme={FANNYPACK_THEME}>
      <ApolloProvider client={client}>
        <Container>
          <Router>
            <Switch>
              <Route
                path={ROUTES.register}
                render={() => (
                  <Page title="Registration - Bunnings Sausage Sizzle">
                    <Register />
                  </Page>
                )}
                exact
              />
              <Route
                path={ROUTES.registerFromConfirmedBooking}
                render={() => (
                  <Page title="Registration - Bunnings Sausage Sizzle">
                    <RegisterFromBooking />
                  </Page>
                )}
                exact
              />
              <Route
                path={ROUTES.registerDeclined}
                render={() => (
                  <Page title="Registration - Bunnings Sausage Sizzle">
                    <RegisterDeclined />
                  </Page>
                )}
                exact
              />
              <Route
                path={ROUTES.registerSuccess}
                render={() => (
                  <Page title="Registration - Bunnings Sausage Sizzle">
                    <RegisterWithStripeSuccess />
                  </Page>
                )}
                exact
              />

              <Route
                path={ROUTES.registerFromConfirmedBookingSuccess}
                render={() => (
                  <Page title="Registration - Bunnings Sausage Sizzle">
                    <RegisterSuccess />
                  </Page>
                )}
                exact
              />
              <Route
                path={ROUTES.registerError}
                render={() => (
                  <Page title="Registration - Bunnings Sausage Sizzle">
                    <RegisterError />
                  </Page>
                )}
                exact
              />
              <Route
                path={ROUTES.registerExistingUser}
                render={() => (
                  <Page title="Registration - Bunnings Sausage Sizzle">
                    <RegisterExistingUser />
                  </Page>
                )}
                exact
              />
              <Route path={ROUTES.login} component={Login} exact />
              <Route path={ROUTES.merchant} component={Merchant} />
              <Route component={Error} />
            </Switch>
          </Router>
        </Container>
      </ApolloProvider>
    </ThemeProvider>
  )
}

export default App
