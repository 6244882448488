import dayjs, { ConfigType } from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'

export const dateFormatter = {
  long: 'dddd, Do [of] MMMM YYYY',
  short: 'DD/MM/YYYY'
}

// https://day.js.org/docs/en/plugin/timezone
dayjs.extend(utc)
dayjs.extend(tz)
//https://github.com/iamkun/dayjs/blob/master/docs/en/Plugin.md#advancedformat
dayjs.extend(advancedFormat)

export function formatDate({
  date,
  template = dateFormatter.long,
  timezone
}: {
  date: ConfigType
  template?: string
  timezone: string
}) {
  return dayjs(date).tz(timezone).format(template)
}
