import React from 'react'
import { ApolloError } from 'apollo-client'
import GraphQLError from './GraphQLError'

interface Props {
  errors: {
    loading: boolean
    error: ApolloError | undefined
  }[]
  layoutProps?: Record<string, any>
}

export const GraphQLErrors: React.FC<Props> = ({
  errors,
  layoutProps = {}
}: Props) => {
  return (
    <>
      {errors.map(
        ({ loading, error }) =>
          error &&
          !loading && (
            <GraphQLError
              key={error.name}
              error={error}
              layoutProps={{ ...layoutProps, margin: '0.5rem' }}
            />
          )
      )}
    </>
  )
}

export default GraphQLErrors
