import React, { useState } from 'react'
import { styled, Button } from 'fannypack'
import { find, get } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import Menu from '../components/Menu'
import PoweredBy from '../components/PoweredBy'
import OrderList from '../components/orders/OrderList'
import OrderDetailsContent from '../components/orderDetails/OrderDetailsContent'
import RefundButton from '../components/orderDetails/RefundButton'
import { useMerchantContext } from './MerchantContext'
import { REFUND_STATUS } from '../constants'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1.5rem 1rem;
  position: relative;
  width: 100%;
`
const Tabs = styled.div`
  display: flex;
  margin-top: 1rem;
`
const Tab = styled.div<{ bold: boolean }>`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  border-bottom: ${(props) => (props.bold ? '3px solid #0D5257' : 'none')};
  padding: 0.25rem 1rem;
  text-align: left;
`

const Orders = () => {
  const history = useHistory()
  const { orderNumber, storeCode } = useParams<{
    orderNumber: string
    storeCode: string
  }>()
  const [tab, setTab] = useState<number>(1)
  const { orders } = useMerchantContext()
  const order = find(orders, { orderNumber })
  return (
    <Container>
      <Menu />
      {!orderNumber && (
        <Tabs>
          <Tab bold={tab === 1} onClick={() => setTab(1)}>
            Orders
          </Tab>
        </Tabs>
      )}
      {orderNumber ? (
        <>{tab === 1 && <OrderDetailsContent />}</>
      ) : (
        <>{tab === 1 && <OrderList />}</>
      )}
      {orderNumber && (
        <>
          {get(order, 'refundStatus') !== REFUND_STATUS.succeeded && (
            <RefundButton />
          )}
          <Button
            onClick={() => history.push(`/${storeCode}`)}
            marginTop="1rem"
          >
            Go back
          </Button>
        </>
      )}
      <PoweredBy />
    </Container>
  )
}

export default Orders
