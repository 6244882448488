import React from 'react'
import { useParams } from 'react-router-dom'
import { getProjectIdByStoreCode } from '../../utils/project'
import RegisterDetails from './RegisterDetails'
import RegisterError from './scenarios/RegisterError'

export function RegisterVendor() {
  const { storeCode } = useParams<{ storeCode: string }>()
  const projectId = getProjectIdByStoreCode(storeCode)

  if (!storeCode || !projectId) return <RegisterError />

  return (
    <RegisterDetails
      {...{
        projectId,
        storeCode,
        usePayment: true
      }}
    />
  )
}

export default RegisterVendor
