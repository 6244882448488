import { gql } from 'apollo-boost'

export const ORDER_UPDATE_SUBSCRIPTION = gql`
  subscription orderUpdatedForBooking {
    orderUpdatedForBooking {
      projectId
      bookingId
      orderNumber
      topic
    }
  }
`
