export type TProject = {
  projectId: string
  stripePublishableKey: string
}

export type TPrice = {
  currency: string
  id: string
  product: string
  unit_amount: number
  active: boolean
}

export type TProduct = {
  description: string
  id: string
  images: string[]
  name: string
  prices?: TPrice[]
}

export type TSite = {
  name: string
  phone: string
  siteId: string
  storeCode: string
  timezone: string
}

export type TBooking = {
  bookingId: string
  end: string
  name: string
  productIds: string[]
  projectId: string
  start: string
  storeCode: string
  vendor: { name: string }
  vendorId: string
}

export type TOrderItem = {
  itemId: string
  totalAmount: number
  unitAmount: number
  quantity: number
  name: string
  currency: string
}

export type TOrder = {
  orderNumber: string
  orderDate: Date
  orderStatus: string
  orderAmount: number
  checkoutSessionId: string
  stripeReceiptUrl: string
  successUrl: string
  vendorId: string
  projectId: string
  items: TOrderItem[]
  bookingId: string
  refundStatus: string
}

export enum BookingStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED'
}
