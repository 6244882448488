import { gql, ApolloQueryResult } from 'apollo-boost'
import client from './client'

export const FIND_SITE = gql`
  query getSite($projectId: ID!, $storeCode: ID!) {
    findSite(projectId: $projectId, storeCode: $storeCode) {
      siteId
      storeCode
      name
      phone
      timezone
    }
  }
`

export const FIND_PROJECT = gql`
  query findProject($projectId: ID!) {
    findProject(projectId: $projectId) {
      projectId
      stripeConnectClientId
      disableLocalzPayBookingMutations
    }
  }
`

export const getData = ({
  projectId,
  storeCode,
  token
}: {
  projectId: string
  storeCode: string
  token: string
}): Promise<ApolloQueryResult<any>> => {
  return client.query({
    query: gql`
      query getProject($projectId: ID!, $storeCode: ID!) {
        findProject(projectId: $projectId) {
          projectId
          stripePublishableKey
        }
        findSite(projectId: $projectId, storeCode: $storeCode) {
          siteId
          storeCode
          name
          phone
          timezone
        }
        findBookingsForToday(projectId: $projectId, storeCode: $storeCode) {
          bookingId
          projectId
          storeCode
          vendorId
          start
          end
          productIds
          name
          vendor {
            name
          }
        }
        findProducts(projectId: $projectId) {
          data {
            id
            name
          }
        }
        findPrices(projectId: $projectId) {
          data {
            id
            active
          }
        }
        findOrders {
          orderNumber
          orderDate
          orderStatus
          orderAmount
          checkoutSessionId
          stripeReceiptUrl
          successUrl
          vendorId
          projectId
          bookingId
          refundId
          refundAmount
          refundStatus
          items {
            itemId
            totalAmount
            unitAmount
            quantity
            name
            currency
          }
        }
      }
    `,
    variables: { projectId, storeCode },
    fetchPolicy: 'network-only',
    context: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  })
}

export const getOrders = ({
  token
}: {
  token: string
}): Promise<ApolloQueryResult<any>> => {
  return client.query({
    query: gql`
      query getOrders {
        findOrders {
          orderNumber
          orderDate
          orderStatus
          orderAmount
          checkoutSessionId
          stripeReceiptUrl
          successUrl
          vendorId
          projectId
          bookingId
          refundId
          refundAmount
          refundStatus
          items {
            itemId
            totalAmount
            unitAmount
            quantity
            name
            currency
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
    context: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  })
}

export const getBalance = ({
  token
}: {
  token: string
}): Promise<ApolloQueryResult<any>> => {
  return client.query({
    query: gql`
      query getBalance {
        findBalance {
          available {
            amount
            currency
          }
          connectReserved {
            amount
            currency
          }
          pending {
            amount
            currency
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
    context: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  })
}

export const getBookingWithSummary = ({
  token
}: {
  token: string
}): Promise<ApolloQueryResult<any>> => {
  return client.query({
    query: gql`
      query findBooking {
        findBooking {
          summary {
            netTurnover
            items {
              name
              quantity
              total
            }
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
    context: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  })
}

export const GET_BOOKING_INVITATION = gql`
  query getInvitation($bookingId: ID!, $invitationCode: String!) {
    findBookingInvitation(
      bookingId: $bookingId
      invitationCode: $invitationCode
    ) {
      bookingId
      projectId
      vendorId
      usePayment
      vendor {
        vendorId
        name
        email
        altName
        verificationStatus
        stripeDetails
      }
      booking {
        storeCode
        name
        status
        start
      }
    }
  }
`
