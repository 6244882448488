import React from 'react'
import { styled, Icon } from 'fannypack'
import Header from '../components/Header'
import PoweredBy from '../components/PoweredBy'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 10;
`
const Body = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.75rem;
`
const Message = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const StyledIcon = styled(Icon)`
  width: 3rem;
  height: 3rem;
`
const Shadow = styled.div`
  margin-top: 0.75rem;
  width: 2.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #eee;
`
const Text = styled.div`
  margin-top: 1.5rem;
  color: #757575;
  font-size: 0.875rem;
  text-align: center;
`

const ErrorView = () => {
  return (
    <Container>
      <Header />
      <Body>
        <Message>
          <StyledIcon icon="solid-exclamation-circle" color="primary" />
          <Shadow />
          <Text>Please try again, something went wrong</Text>
        </Message>
        <PoweredBy />
      </Body>
    </Container>
  )
}

export default ErrorView
