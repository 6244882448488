import React, { ReactNode } from 'react'
import { Card, Page } from 'fannypack'
import Privacy from '../../components/register/Privacy'

interface RegisterScenarioProps {
  title: string
  children?: ReactNode
}

const RegisterScenario = ({
  title,
  children: mainContent
}: RegisterScenarioProps) => {
  return (
    <Page.Content
      breakpoint="widescreen"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Card.Card maxWidth="450px" marginTop="major-3" padding="major-6">
        <Card.Header>
          <Card.Title color="primary">{title}</Card.Title>
        </Card.Header>
        <Card.Content
          display="flex"
          flexDirection="column"
          alignItems="center"
          minHeight="200px"
          color="#54595f"
        >
          {mainContent}
        </Card.Content>
        <Privacy />
      </Card.Card>
    </Page.Content>
  )
}
export default RegisterScenario
