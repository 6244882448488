import React from 'react'
import { Box, Alert } from 'fannypack'
import RegisterScenario from '../RegisterScenario'

export function RegisterExistingUser() {
  return (
    <RegisterScenario title={'User already exists'}>
      <Box marginBottom="major-4">
        <Alert hasTint border="0" type="danger" hasIcon>
          <p>You already created an account</p>
        </Alert>
        <p>If this is a mistake please contact your Activity Organiser.</p>
      </Box>
    </RegisterScenario>
  )
}
export default RegisterExistingUser
