import React, { useState } from 'react'
import { styled, Icon, Button, Menu as FPMenu } from 'fannypack'
import { useHistory, useParams } from 'react-router-dom'
import { LOCAL_STORAGE_JWT } from '../constants'
import PoweredBy from '../components/PoweredBy'
import SalesSummary from './SalesSummary'
import collectEvent from '../utils/events'

const StyledMenuIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
`
const StyledCloseIcon = styled(Icon)`
  width: 2.5rem;
  height: 2.5rem;
`
const StyledButton = styled(Button)<{ alignSelf?: string }>`
  padding: 0;
  background-color: transparent;
  border: none;
  min-height: unset;
  width: 2rem;
  align-self: ${(props) => props.alignSelf};
  &:focus {
    box-shadow: none;
  }
  &:hover {
    background-color: transparent;
    &:active {
      background-color: transparent;
    }
  }
`
const Container = styled.div`
  height: 100%;
  background-color: #fafafe;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
`
const StyledItem = styled(FPMenu.Item)`
  color: #757575;
  font-weight: 500;
  border: solid 1px #f0f0fc;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
`
const StyledMenu = styled(FPMenu)`
  flex: 1;
`

const Menu = () => {
  const history = useHistory()
  const [open, setOpen] = useState<boolean>(false)
  const { storeCode } = useParams<{ storeCode: string }>()

  const [showSummary, setShowSummary] = useState<boolean>(false)

  const handleLogout = () => {
    localStorage.removeItem(LOCAL_STORAGE_JWT)

    collectEvent({
      storeCode,
      eventLabel: 'LOGOUT'
    })

    history.push('/')
  }
  const handleClose = () => {
    setOpen(false)
    setShowSummary(false)
  }
  return (
    <>
      <StyledButton onClick={() => setOpen(true)} data-testid="menu-button">
        <StyledMenuIcon icon="solid-bars" color="#757575" />
      </StyledButton>
      {open && (
        <Container>
          <StyledButton
            onClick={handleClose}
            marginTop="1rem"
            marginLeft="1rem"
          >
            <StyledCloseIcon icon="solid-times" color="#757575" />
          </StyledButton>
          {showSummary ? (
            <SalesSummary />
          ) : (
            <StyledMenu a11yTitle="menu">
              <FPMenu.Group>
                <StyledItem padding="1rem" onClick={() => setShowSummary(true)}>
                  Sales Summary
                  <StyledIcon icon="solid-angle-right" color="#757575" />
                </StyledItem>
                <StyledItem
                  padding="1rem"
                  onClick={() =>
                    window.open(
                      'https://forms.office.com/Pages/ResponsePage.aspx?id=ziMh2eoOmkiFyrtOPcUejcFAEzL2l_9Bj3qke5M9SgxUQzBGWEVaSU9ZUDFUSFlVTkI3UzhOWlFYSCQlQCN0PWcu'
                    )
                  }
                >
                  Feedback
                  <StyledIcon icon="solid-angle-right" color="#757575" />
                </StyledItem>
              </FPMenu.Group>
            </StyledMenu>
          )}
          {!showSummary && (
            <Button
              onClick={handleLogout}
              marginLeft="1rem"
              marginRight="1rem"
              height="3rem"
              color="#757575"
            >
              Log out
            </Button>
          )}
          <PoweredBy />
        </Container>
      )}
    </>
  )
}

export default Menu
