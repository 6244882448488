import { getProjectIdByStoreCode } from './project'

function collectEvent({
  storeCode,
  eventLabel,
  ...rest
}: {
  storeCode: string
  eventLabel:
    | 'PAGE_VIEW'
    | 'LINK_CLICK'
    | 'LOGIN'
    | 'LOGOUT'
    | 'COMPONENT_CLICK'
  userId?: string
  component?: string
}) {
  const projectId = getProjectIdByStoreCode(storeCode)
  const endpoint = `${process.env.REACT_APP_EVENT_COLLECTOR_URL}/projects/${projectId}/event`

  const payload = {
    projectId,
    appId: 'SIZZLE_MERCHANT_WEB',
    eventLabel,
    locationId: storeCode,
    ...rest
  }

  return fetch(endpoint, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
}

export default collectEvent
