import React, { useCallback, useState, useEffect } from 'react'
import {
  Box,
  Label,
  SelectField,
  Textarea,
  Button,
  Text,
  styled
} from 'fannypack'

import RegisterScenario from './RegisterScenario'
import { useLocation } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { FIND_PROJECT, GET_BOOKING_INVITATION } from '../../sdk/query'
import {
  UPDATE_BOOKING_DECLINE_REASON,
  UPDATE_BOOKING_STATUS
} from '../../sdk/mutation'
import GraphQLErrors from '../../components/register/GraphQLErrors'
import BookingError from './scenarios/BookingError'
import LoadingScreen from '../../components/LoadingScreen'
import { BookingStatus } from '../../types'
import RegistrationDisabled from './scenarios/RegistrationDisabled'

const Required = styled.span`
  color: red;
`

const Error = styled.p`
  color: red;
  margin-bottom: 0.5rem;
  margin-top: 0;
`

export function RegisterDeclined() {
  const { hash } = useLocation()
  const [errors, setErrors] = useState<{
    reason?: string
    additionalInfo?: string
  }>({})
  const [bookingId = '', invitationCode = ''] = hash?.slice(1)?.split(':')
  const [reason, setReason] = useState('')
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [reasonComplete, setReasonComplete] = useState(false)
  const [step, setStep] = useState<'confirmed' | 'requestingConfirmation'>(
    'requestingConfirmation'
  )

  const [
    updateBookingStatus,
    { error: updateBookingStatusError, loading: updateBookingStatusLoading }
  ] = useMutation(UPDATE_BOOKING_STATUS)

  const [
    updateBookingDeclineReason,
    { loading: updateBookingDeclineReasonLoading }
  ] = useMutation(UPDATE_BOOKING_DECLINE_REASON)

  const [
    getProjectQuery,
    { data: projectQuery, loading: projectQueryLoading }
  ] = useLazyQuery(FIND_PROJECT)

  const {
    data: invitation,
    error: invitationError,
    loading: invitationLoading
  } = useQuery(GET_BOOKING_INVITATION, {
    variables: { bookingId, invitationCode }
  })

  const onSubmit = (e: any) => {
    e.preventDefault()

    if (!reason) {
      setErrors((e) => ({
        ...e,
        reason: 'Please select a reason'
      }))

      return
    }

    if (!invitation) {
      setErrors((e) => ({
        ...e,
        invitation: 'Could not find your invitation'
      }))
    }

    setReasonComplete(true)

    const { findBookingInvitation } = invitation

    updateBookingDeclineReason({
      variables: {
        input: {
          bookingId,
          invitationCode,
          projectId: findBookingInvitation.projectId,
          lastUpdatedBy: findBookingInvitation?.vendor?.name,
          declineReason: {
            reason,
            additionalInfo
          }
        }
      }
    })
  }

  useEffect(() => {
    const projectId = invitation?.findBookingInvitation?.projectId
    if (projectId) {
      getProjectQuery({ variables: { projectId } })
    }
  }, [invitation, getProjectQuery])

  const updateStatus = useCallback(async () => {
    if (!invitation) {
      return
    }
    try {
      const { findBookingInvitation } = invitation
      await updateBookingStatus({
        variables: {
          updateConfig: {
            projectId: findBookingInvitation.projectId,
            bookingId: findBookingInvitation.bookingId,
            invitationCode: invitationCode,
            status: BookingStatus.DECLINED,
            lastUpdatedBy: findBookingInvitation?.vendor?.name
          }
        }
      })
    } catch (e) {}
  }, [updateBookingStatus, invitation, invitationCode])

  const onDecline = async () => {
    setStep('confirmed')
    updateStatus()
  }

  const renderStep = () => {
    switch (step) {
      case 'requestingConfirmation':
        return (
          <>
            Bunnings mobile payments will not be available from this invitation
            once declined. If you change your mind, please contact the store's
            Activities Organiser. 
            <Button marginTop="major-4" onClick={onDecline}>
              Yes, decline
            </Button>
          </>
        )
      case 'confirmed':
        return (
          <Box marginBottom="major-4">
            You have confirmed that you will not be using mobile payments for
            this event.
            {reasonComplete ? (
              <>
                <br />
                <br />
                <Text textAlign="center">Thank you for letting us know!</Text>
              </>
            ) : (
              <>
                <h3>
                  Please let us know why you have declined to use Mobile
                  Payments to help us improve the experience.
                </h3>

                <form onSubmit={onSubmit}>
                  <Label htmlFor="reason">
                    Reason <Required>*</Required>
                  </Label>
                  <SelectField
                    required
                    name="reason"
                    a11yId="reason"
                    onChange={(e: any) => {
                      setErrors({})
                      setReason(e.target.value)
                    }}
                    value={reason}
                    options={[
                      {
                        label: 'Please select',
                        value: '',
                        disabled: true
                      },
                      {
                        value: 'I plan to use my own device',
                        label: 'I plan to use my own device'
                      },

                      {
                        value: `I don't have sufficient information to proceed`,
                        label: `I don't have sufficient information to proceed`
                      },

                      {
                        value: "I'm uncomfortable with technology",
                        label: "I'm uncomfortable with technology"
                      },
                      {
                        value: 'The process is too complicated',
                        label: 'The process is too complicated'
                      },
                      {
                        value: 'Other',
                        label: 'Other'
                      }
                    ]}
                  />

                  {errors.reason && <Error>{errors.reason}</Error>}

                  {reason === 'Other' && (
                    <>
                      <br />
                      <Label htmlFor="additional-information">
                        Additional information
                      </Label>
                      <Textarea
                        value={additionalInfo}
                        onChange={(e: any) => {
                          setErrors({})
                          setAdditionalInfo(e.target.value)
                        }}
                        isRequired
                        name="additionalInfo"
                        a11yId="additional-information"
                        placeholder="Please provide additional information"
                      />
                    </>
                  )}

                  {errors.additionalInfo && (
                    <Error>{errors.additionalInfo}</Error>
                  )}

                  <br />
                  <Button
                    backgroundColor="primary"
                    color="white"
                    float="right"
                    isLoading={updateBookingDeclineReasonLoading}
                    type="submit"
                  >
                    Submit
                  </Button>
                </form>
              </>
            )}
          </Box>
        )
    }
  }

  if (invitationLoading || updateBookingStatusLoading || projectQueryLoading) {
    return <LoadingScreen />
  }

  if (projectQuery?.findProject?.disableLocalzPayBookingMutations) {
    return <RegistrationDisabled />
  }
  if (!invitationLoading && invitationError) {
    return <BookingError error={invitationError.graphQLErrors[0]} />
  }
  return (
    <RegisterScenario
      title={
        step === 'requestingConfirmation'
          ? 'Are you sure you do not want to use our mobile payments option?'
          : 'Mobile Payments Declined'
      }
    >
      <GraphQLErrors
        errors={[
          {
            loading: updateBookingStatusLoading,
            error: updateBookingStatusError
          }
        ]}
      />

      {renderStep()}
    </RegisterScenario>
  )
}
export default RegisterDeclined
