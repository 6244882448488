import React from 'react'
import { styled } from 'fannypack'
import { useHistory, useParams } from 'react-router-dom'
import { isEmpty, sortBy } from 'lodash'
import OrderCard from './OrderCard'
import { useMerchantContext } from '../../containers/MerchantContext'
import { TOrder } from '../../types'
import collectEvent from '../../utils/events'

const Container = styled.div`
  margin-top: 1rem;
  flex: 1 1 auto;
  overflow: auto;
  height: 0;
`

const OrderList = () => {
  const { storeCode } = useParams<{ storeCode: string }>()
  const history = useHistory()
  const { orders } = useMerchantContext()
  return isEmpty(orders) ? (
    // TODO: add empty order list screen
    <Container />
  ) : (
    <Container>
      {sortBy(orders, ['orderDate'])
        .reverse()
        .map((order: TOrder) => (
          <OrderCard
            key={order.orderNumber}
            onClick={() => {
              collectEvent({
                storeCode,
                eventLabel: 'COMPONENT_CLICK',
                component: 'ORDER_CARD'
              })
              history.push(`/${storeCode}/${order.orderNumber}`)
            }}
            isOrderDetails={false}
            order={order}
          />
        ))}
    </Container>
  )
}

export default OrderList
