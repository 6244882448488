import React, { useEffect } from 'react'
import { Alert, List, ListItem } from 'fannypack'
import { ApolloError } from 'apollo-client'
import { sendSentryError } from '../../utils/sentry'

interface Props {
  error: ApolloError
  layoutProps?: Record<string, any>
}

export const GraphQLError: React.FC<Props> = ({
  error,
  layoutProps = {}
}: Props) => {
  useEffect(() => {
    sendSentryError(error, { desc: 'Failed to register' })
  }, [error])
  return (
    <Alert type="danger" hasTint {...layoutProps}>
      <List>
        {/**
         * Group the graphql errors and then list them out
         *
         * This prevents duplicate errors being displayed
         */}
        {Array.from(
          error?.graphQLErrors?.reduce(
            (acc, cv) => acc.add(cv.message),
            new Set<string>()
          ) || []
        ).map((message: string) => (
          <ListItem
            data-testid="graphql-error"
            key={message.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}
          >
            {message}
          </ListItem>
        ))}
      </List>
    </Alert>
  )
}

export default GraphQLError
