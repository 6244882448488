import * as Sentry from '@sentry/browser'

export const sendSentryWarning = (message: string, data?: any) => {
  Sentry.withScope((scope) => {
    scope.setLevel(Sentry.Severity.Warning)
    scope.setExtra('data', data)
    Sentry.captureMessage(message, Sentry.Severity.Warning)
  })
}

export const sendSentryError = (error: Error, data?: any) => {
  Sentry.withScope((scope) => {
    scope.setLevel(Sentry.Severity.Error)
    scope.setExtra('data', data)
    Sentry.captureException(error)
  })
}
