import React from 'react'
import { Box } from 'fannypack'
import RegisterScenario from '../RegisterScenario'

export function RegisterSuccess() {
  return (
    <RegisterScenario title="Your booking has been confirmed!">
      <Box marginBottom="major-4">
        An email with instructions on how to get started and take payments on
        the day has been sent to you.
      </Box>
    </RegisterScenario>
  )
}
export default RegisterSuccess
