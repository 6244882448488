import { gql, ExecutionResult } from 'apollo-boost'
import client from './client'

export const login = ({
  projectId,
  storeCode,
  bookingCode
}: {
  projectId: string
  storeCode: string
  bookingCode: string
}): Promise<ExecutionResult<any>> => {
  return client.mutate({
    mutation: gql`
      mutation loginBooking($projectId: ID!, $storeCode: ID!, $code: String!) {
        loginBooking(
          projectId: $projectId
          storeCode: $storeCode
          code: $code
        ) {
          token
        }
      }
    `,
    variables: { projectId, storeCode, code: bookingCode }
  })
}

export const refundOrder = ({
  orderNumber,
  refundPin,
  token
}: {
  orderNumber: string
  refundPin: string
  token: string
}): Promise<ExecutionResult<any>> => {
  return client.mutate({
    mutation: gql`
      mutation refundOrder($orderNumber: ID!, $refundPin: String!) {
        refundOrder(orderNumber: $orderNumber, refundPin: $refundPin) {
          success
          message
        }
      }
    `,
    variables: { orderNumber, refundPin },
    context: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  })
}

export const UPDATE_BOOKING_STATUS = gql`
  mutation updateBookingStatus($updateConfig: UpdateBookingStatusInput!) {
    updateBookingStatus(input: $updateConfig) {
      bookingId
    }
  }
`

export const UPDATE_BOOKING_DECLINE_REASON = gql`
  mutation updateBookingDeclineReason(
    $input: UpdateBookingDeclineReasonInput!
  ) {
    updateBookingDeclineReason(input: $input) {
      bookingId
      projectId
      vendorId
      eventType
      declineReason {
        reason
        additionalInfo
      }
    }
  }
`
