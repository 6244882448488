import React from 'react'
import { Spinner, styled } from 'fannypack'
const CenteredSpinner = styled(Spinner)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`

const LoadingScreen = () => {
  return <CenteredSpinner size="5rem" />
}

export default LoadingScreen
