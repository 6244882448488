import React from 'react'
import { Label as FannyLabel, styled } from 'fannypack'

const PrimaryLabel = styled(FannyLabel)`
  font-size: 14px;
  font-family: 'Futura';
`

const SecondaryLabel = styled(FannyLabel)`
  margin-top: 10px;
  font-weight: lighter;
  font-size: 14px;
`

const Label = ({
  primaryText,
  secondaryText,
  htmlFor
}: {
  primaryText: string
  secondaryText?: string
  htmlFor?: string
}) => {
  return (
    <>
      <PrimaryLabel htmlFor={htmlFor}>
        <b>{primaryText}</b>
      </PrimaryLabel>
      {secondaryText && (
        <SecondaryLabel htmlFor={htmlFor}>{secondaryText}</SecondaryLabel>
      )}
    </>
  )
}

export default Label
