import React, { useEffect } from 'react'
import { styled } from 'fannypack'
import Header from '../components/Header'

type Props = {
  title: string
  children: any
}

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`
const Body = styled.div`
  flex: 1;
`

const Page = ({ title, children }: Props) => {
  useEffect(() => {
    document.title = title || ''
  }, [title])
  return (
    <Container>
      <Header />
      <Body>{children}</Body>
    </Container>
  )
}

export default Page
