import React from 'react'
import { styled, palette, Image } from 'fannypack'
import { get } from 'lodash'
import { LOGO_URL } from '../constants'
import { useMerchantContext } from '../containers/MerchantContext'

const Title = styled.div`
  font-weight: 900;
  text-transform: capitalize;
  text-align: left;
`
const SubTitle = styled.div`
  text-transform: capitalize;
  text-align: left;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`
const Container = styled.div`
  width: 100%;
  display: flex;
  color: #fff;
  align-items: center;
  padding: 1rem 0.5rem;
  background-color: ${palette('primary')};
`

const Header = () => {
  const { site, bookings } = useMerchantContext()
  return (
    <Container>
      <Image src={LOGO_URL} alt="logo" height="2.5rem" />
      <TitleContainer>
        <Title>
          {get(site, 'name', '') && <>Bunnings {get(site, 'name', '')}</>}
        </Title>
        <SubTitle>
          {get(bookings, '0.vendor.name', '') && (
            <>{get(bookings, '0.vendor.name', '')}</>
          )}
        </SubTitle>
      </TitleContainer>
    </Container>
  )
}

export default Header
