import React from 'react'
import RegisterScenario from '../RegisterScenario'
import { Box } from 'fannypack'

const RegistrationDisabled = () => {
  return (
    <RegisterScenario title="Mobile Payments are temporarily disabled">
      <Box textAlign="left">
        <p>We’re sorry! </p>
        <p>
          Please be aware that this service is temporarily unavailable as it's
          undergoing maintenance updates.
        </p>
        <p>
          New registrations will not be possible until we’re back up and
          running.
        </p>
        <p>We apologise for any inconvenience.</p>
      </Box>
    </RegisterScenario>
  )
}

export default RegistrationDisabled
