import React, { Fragment } from 'react'
import { Text, Columns, Column } from 'fannypack'

import { BUSSINESS_TYPE } from '../../constants'
import Register from '../../components/register/Register'

type StripeRegistrationProps = {
  handleRegistration: (businessType: string) => void
}
const StripeRegistration = ({
  handleRegistration
}: StripeRegistrationProps) => {
  return (
    <>
      <Text fontSize="14px" color="#757575">
        After selecting either Individual or Company, you will be taken into
        Stripe to complete the registration process.
      </Text>
      <Columns>
        <Column spread={6}>
          <Register
            helpText={[
              <Fragment key="text-1">
                Select this option if your group{' '}
              </Fragment>,
              <b key="text-2">does not </b>,
              <Fragment key="text-3">have an ABN/ACN/NZBN</Fragment>
            ]}
            buttonLabel="Individual"
            onRegister={() => handleRegistration(BUSSINESS_TYPE.individual)}
          />
        </Column>
        <Column spread={6}>
          <Register
            helpText="Select this option if your group has an ABN/ACN/NZBN"
            buttonLabel="Company"
            onRegister={() => handleRegistration(BUSSINESS_TYPE.company)}
          />
        </Column>
      </Columns>
    </>
  )
}

export default StripeRegistration
