import React from 'react'
import { Box } from 'fannypack'
import RegisterScenario from '../RegisterScenario'

export function RegisterWithStripeSuccess() {
  return (
    <RegisterScenario
      title={'Thank you for setting up an account with Stripe.'}
    >
      <Box marginBottom="major-4">
        <p>
          Stripe will send you an email to complete the registration process.
        </p>
        <p>
          Next steps and instructions on how to use mobile payments will be
          emailed to you separately by the Bunnings Sausage Sizzle team.
        </p>
        <p>
          Please make sure to keep the unique access codes we email you secure
          until the date of your Sausage Sizzle as you will need them on the
          day.
        </p>
      </Box>
    </RegisterScenario>
  )
}
export default RegisterWithStripeSuccess
