import React from 'react'
import { useLocation } from 'react-router-dom'
import { GET_BOOKING_INVITATION } from '../../sdk/query'
import { useQuery } from '@apollo/react-hooks'

import BookingError from './scenarios/BookingError'
import LoadingScreen from '../../components/LoadingScreen'
import RegisterDetails from './RegisterDetails'

const RegisterFromBooking = () => {
  const { hash } = useLocation()
  const [bookingId = '', invitationCode = ''] = hash?.slice(1)?.split(':')
  const { data, error, loading } = useQuery(GET_BOOKING_INVITATION, {
    variables: { bookingId, invitationCode }
  })
  const findBookingInvitation = data?.findBookingInvitation

  if (loading) return <LoadingScreen />
  if (error) {
    return <BookingError error={error?.graphQLErrors[0]} />
  }
  if (
    !findBookingInvitation ||
    !findBookingInvitation.booking ||
    !findBookingInvitation.vendor
  ) {
    return <BookingError error={['NOT_FOUND']} />
  }
  const { name: vendorName, ...restOfVendor } = findBookingInvitation.vendor
  const { name: bookingName, ...restOfBooking } = findBookingInvitation.booking
  return (
    <RegisterDetails
      {...{
        // project
        projectId: findBookingInvitation?.projectId,
        usePayment: findBookingInvitation?.usePayment,

        // vendor
        vendorName,
        ...restOfVendor,

        // booking
        bookingId,
        invitationCode,
        bookingName,
        ...restOfBooking
      }}
    />
  )
}

export default RegisterFromBooking
