import { isEmpty, filter, get } from 'lodash'
import { TProduct, TPrice } from '../types'

export const getProductsWithPrice = ({
  products,
  prices
}: {
  products: TProduct[]
  prices: TPrice[]
}) => {
  if (isEmpty(products)) {
    return []
  }
  return products.map((product) => ({
    ...product,
    prices: filter(
      prices,
      (price) =>
        get(price, 'product') === get(product, 'id') && !!get(price, 'active')
    )
  }))
}
