import { HttpLink, ApolloClient, InMemoryCache, split } from 'apollo-boost'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { LOCAL_STORAGE_JWT } from '../constants'

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_PAYMENT_URL}/graphql`
})

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_PAYMENT_WS_URL}/graphql`,
  options: {
    reconnect: true,
    connectionParams: () => ({
      Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_JWT)}`
    })
  }
})

// https://github.com/apollographql/apollo-link/issues/197
export const changeSubscriptionToken = (token: string) => {
  // @ts-ignore
  if (wsLink.subscriptionClient.connectionParams.Authorization === token) {
    return
  }
  // @ts-ignore
  wsLink.subscriptionClient.connectionParams.Authorization = token
  // @ts-ignore
  wsLink.subscriptionClient.close()
  // @ts-ignore
  wsLink.subscriptionClient.connect()
}

export const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

export default client
