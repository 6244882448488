import React from 'react'
import { styled, Text } from 'fannypack'
import { get, isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { TOrder } from '../../types'
import { REFUND_STATUS } from '../../constants'

const Container = styled.div`
  display: flex;
  border-radius: 0.5rem;
  border: solid 1px rgba(151, 151, 151, 0.26);
  background-color: #ffffff;
  padding: 1rem;
  margin-bottom: 0.5rem;
  color: #757575;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
`
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`

const OrderNumber = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
`
const Date = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-weight: 500;
  color: #a3a3a3;
`
const Product = styled.div`
  font-weight: 500;
  margin-top: 0.25rem;
`
const Price = styled.div`
  text-align: left;
  margin-top: 1rem;
`
const RefundStatus = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: #f7941d;
  font-weight: 500;
`

const OrderCard = ({
  onClick,
  isOrderDetails,
  order
}: {
  onClick?: Function
  isOrderDetails: boolean
  order: TOrder
}) => {
  return (
    // @ts-ignore
    <Container onClick={onClick}>
      <LeftColumn>
        <OrderNumber>{get(order, 'orderNumber', '').substring(2)}</OrderNumber>
        {!isEmpty(get(order, 'items', [])) && (
          <>
            {order.items.map((item) => (
              <Product key={get(item, 'itemId')}>
                <Text>
                  {get(item, 'quantity')} x {get(item, 'name')}
                </Text>
                {isOrderDetails && (
                  <Text>
                    , Subtotal: $
                    {(get(item, 'totalAmount', 0) / 100).toFixed(2)}
                  </Text>
                )}
              </Product>
            ))}
            {isOrderDetails && (
              <Price>
                Total: ${(get(order, 'orderAmount', 0) / 100).toFixed(2)}
              </Price>
            )}
          </>
        )}
      </LeftColumn>

      <Date>{dayjs(get(order, 'orderDate')).format('D/M/YYYY')}</Date>
      {get(order, 'refundStatus') === REFUND_STATUS.succeeded && (
        <RefundStatus>Refunded</RefundStatus>
      )}
    </Container>
  )
}

export default OrderCard
