import React from 'react'
import { Box, Alert } from 'fannypack'
import RegisterScenario from '../RegisterScenario'

const toMessage = (msg: string) => {
  switch (msg) {
    case 'Booking is CONFIRMED':
      return (
        <RegisterScenario title={'Booking already confirmed'}>
          <Box marginBottom="major-4">
            Your booking has already been confirmed with us. If this was a
            mistake, please speak with your Activity Organiser.
          </Box>
        </RegisterScenario>
      )
    case 'Booking is DECLINED':
      return (
        <RegisterScenario title={'Booking already declined'}>
          <Box marginBottom="major-4">
            You have already declined your booking with us. If this was a
            mistake, please speak with your Activity Organiser.
          </Box>
        </RegisterScenario>
      )
    default:
      return (
        <RegisterScenario title={'Registration Error'}>
          <Box marginBottom="major-4">
            <Alert hasTint border="0" type="danger" hasIcon>
              Sorry but an error has occurred. Please contact your Activity
              Organiser to resolve this issue.
            </Alert>
          </Box>
        </RegisterScenario>
      )
  }
}

const BookingError = ({ error }: { error?: any }) => {
  return toMessage(error.message)
}
export default BookingError
