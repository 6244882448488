import { find, get } from 'lodash'
import { COUNTRIES, STORE_CODE_MAPPING, PRIVACY_LINK } from '../constants'

const getCountryByStoreCode = (storeCode: string) => {
  const country = get(
    find(STORE_CODE_MAPPING, { projectStoreCode: storeCode }),
    'country'
  )
  return country
}

export const getProjectIdByStoreCode = (storeCode: string) => {
  if (storeCode) {
    const country = getCountryByStoreCode(storeCode)
    if (country === COUNTRIES.nz) {
      return process.env.REACT_APP_PROJECT_ID_NZ
    }
    return process.env.REACT_APP_PROJECT_ID_AU
  }
  return ''
}

export const getPrivacyLinkByStoreCode = (storeCode: string) => {
  if (storeCode) {
    const country = getCountryByStoreCode(storeCode)
    if (country === COUNTRIES.nz) {
      return PRIVACY_LINK.nz
    }
    return PRIVACY_LINK.au
  }
  return PRIVACY_LINK.au
}
