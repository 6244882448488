import React, { useEffect, useCallback, useState } from 'react'
import { Switch, Route, useParams, useHistory } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import { styled, Spinner } from 'fannypack'
import dayjs from 'dayjs'
import { useSubscription } from '@apollo/react-hooks'
// @ts-ignore
import jwt from 'jsonwebtoken'
import { ROUTES, LOCAL_STORAGE_JWT } from '../constants'
import Error from './Error'
import Orders from './Orders'
import * as sdk from '../sdk'
import { TProject, TProduct, TBooking, TSite, TOrder } from '../types'
import { getProjectIdByStoreCode } from '../utils/project'
import { getProductsWithPrice } from '../utils/product'
import { sendSentryError, sendSentryWarning } from '../utils/sentry'
import { getTodayOrders } from '../utils/order'
import MerchantContext from './MerchantContext'
import Header from '../components/Header'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`
const StyledSpinner = styled(Spinner)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Body = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Merchant = () => {
  const history = useHistory()
  const { storeCode } = useParams<{ storeCode: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<{
    project: TProject | undefined
    products: TProduct[]
    site: TSite | undefined
    bookings: TBooking[]
    orders: TOrder[]
  }>({
    project: undefined,
    products: [],
    site: undefined,
    bookings: [],
    orders: []
  })
  const [showError, setShowError] = useState<boolean>(false)

  const forceLogout = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_JWT)
    history.push('/')
  }, [history])

  const getProjectAndProducts = useCallback(
    async (token: string) => {
      try {
        if (isEmpty(get(data, 'project'))) {
          setIsLoading(true)
          const res = await sdk.getData({
            projectId: getProjectIdByStoreCode(storeCode)!,
            storeCode,
            token
          })
          const project = get(res, 'data.findProject', {})
          const products = get(res, 'data.findProducts.data', [])
          const prices = get(res, 'data.findPrices.data', [])
          const site = get(res, 'data.findSite', {})
          const bookings = get(res, 'data.findBookingsForToday', [])
          const orders = getTodayOrders({
            orders: get(res, 'data.findOrders', []),
            site
          })
          setData({
            project,
            products: getProductsWithPrice({ products, prices }),
            site,
            bookings,
            orders
          })
          setIsLoading(false)
        }
      } catch (e: any) {
        setShowError(true)
        sendSentryError(e, {
          desc: 'Failed to get project & products',
          storeCode,
          projectId: getProjectIdByStoreCode(storeCode)!
        })
        setIsLoading(false)
        forceLogout()
      }
    },
    [storeCode, data, forceLogout]
  )

  const getOrders = useCallback(async () => {
    const token = localStorage.getItem(LOCAL_STORAGE_JWT)!
    try {
      const res = await sdk.getOrders({
        token
      })
      setData({
        ...data,
        orders: getTodayOrders({
          orders: get(res, 'data.findOrders', []),
          site: get(data, 'site')!
        })
      })
    } catch (e: any) {
      sendSentryError(e, {
        desc: 'Failed to get orders',
        storeCode,
        projectId: getProjectIdByStoreCode(storeCode)!
      })
    }
  }, [data, storeCode])

  useEffect(() => {
    try {
      const token = localStorage.getItem(LOCAL_STORAGE_JWT)
      const { exp } = jwt.decode(token)
      if (dayjs().isAfter(dayjs.unix(exp))) {
        localStorage.removeItem(LOCAL_STORAGE_JWT)
        history.push('/')
        sendSentryWarning('Jwt is invalid')
      } else {
        getProjectAndProducts(token!)
      }
    } catch (e: any) {
      localStorage.removeItem(LOCAL_STORAGE_JWT)
      history.push('/')
      sendSentryError(e, {
        desc: 'Failed to decode jwt',
        storeCode,
        projectId: getProjectIdByStoreCode(storeCode)!
      })
    }
  }, [getProjectAndProducts, history, storeCode])

  useSubscription(sdk.ORDER_UPDATE_SUBSCRIPTION, {
    onSubscriptionData: async (options: any) => {
      try {
        if (
          !isEmpty(get(options, 'subscriptionData.data.orderUpdatedForBooking'))
        ) {
          getOrders()
        }
      } catch (e: any) {
        sendSentryError(e, {
          desc: 'Failed to handle order update event',
          storeCode,
          projectId: getProjectIdByStoreCode(storeCode)!
        })
      }
    }
  })

  return (
    <MerchantContext.Provider
      value={{
        ...data,
        setShowError
      }}
    >
      <Container>
        <Header />
        <Body>
          {isLoading && <StyledSpinner color="primary" size="large" />}
          {showError && <Error />}
          {!isLoading && !showError && !isEmpty(get(data, 'project')) && (
            <Switch>
              <Route path={ROUTES.orders} component={Orders} exact />
              <Route path={ROUTES.order} component={Orders} exact />
              <Route component={Error} />
            </Switch>
          )}
        </Body>
      </Container>
    </MerchantContext.Provider>
  )
}

export default Merchant
