import React, { useMemo, useCallback } from 'react'
import { curry, get } from 'lodash'
import { Box, Card, Page, Spinner, Text, Input, Button } from 'fannypack'
import { string, object } from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { FIND_PROJECT, FIND_SITE } from '../../sdk/query'
import { UPDATE_BOOKING_STATUS } from '../../sdk/mutation'
import { ROUTES } from '../../constants'
import Label from '../../components/Label'
import Privacy from '../../components/register/Privacy'
import validationResolver from '../../utils/validationResolver'
import { useHistory } from 'react-router-dom'
import GraphQLErrors from '../../components/register/GraphQLErrors'
import StripeRegistration from '../../components/register/StripeRegistraion'
import LoadingScreen from '../../components/LoadingScreen'
import { formatDate } from '../../utils/formatDate'
import { BookingStatus } from '../../types'
import RegisterScenario from './RegisterScenario'
import RegistrationDisabled from './scenarios/RegistrationDisabled'

type StripeConnectParams = {
  businessType: string
  clientID: string
  state: string
}

type RegisterDetailsProps = {
  projectId: string
  usePayment?: boolean

  storeCode: string
  bookingName?: string
  bookingId?: string
  invitationCode?: string
  start?: string

  vendorId?: string
  vendorName?: string
  email?: string
  verificationStatus?: string
  stripeDetails?: any
  altName?: string
}

const handleStripeConnect = ({
  businessType,
  state,
  clientID
}: StripeConnectParams) => {
  const redirectURI = `${process.env.REACT_APP_PAYMENT_URL}/webhook/stripeConnect`
  const encodedState = encodeURIComponent(state)
  const stripeBusinessType = `stripe_user[business_type]=${businessType}`
  window.location.assign(
    `https://connect.stripe.com/express/oauth/authorize?client_id=${clientID}&state=${encodedState}&redirect_uri=${redirectURI}&${stripeBusinessType}`
  )
}

const RegisterDetails: React.FC<RegisterDetailsProps> = ({
  projectId,
  usePayment,
  storeCode,
  bookingId,
  invitationCode,
  start,

  vendorName,
  verificationStatus,
  altName
}) => {
  const history = useHistory()

  const { data: projectQuery, loading: projectQueryLoading } = useQuery(
    FIND_PROJECT,
    {
      variables: { projectId }
    }
  )

  const [
    updateBookingStatus,
    { error: updateBookingStatusError, loading: updateBookingStatusLoading }
  ] = useMutation(UPDATE_BOOKING_STATUS)

  const validationSchema = useMemo(
    () =>
      object({
        vendorName: string().required('Community group name is required'),
        alternativeName: string()
      }),
    []
  )

  const { errors, control, getValues, trigger } = useForm<{
    vendorName: string
    alternativeName: string
  }>({
    resolver: validationResolver(validationSchema),
    defaultValues: {
      // vendor name
      vendorName: vendorName || '',
      // Stripe meta data
      alternativeName: altName || ''
    }
  })

  const handleRegistration = useCallback(
    async () => {
      try {
        await updateBookingStatus({
          variables: {
            updateConfig: {
              projectId: projectId,
              bookingId: bookingId,
              invitationCode: invitationCode,
              status: BookingStatus.CONFIRMED,
              lastUpdatedBy: vendorName
            }
          }
        })

        history.push(ROUTES.registerFromConfirmedBookingSuccess)
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bookingId, invitationCode, projectId, updateBookingStatus, vendorName]
  )

  const {
    loading: getSiteLoading,
    error: getSiteError,
    data: siteData
  } = useQuery(FIND_SITE, {
    variables: { projectId, storeCode }
  })

  const handleRegistrationWithStripe = (businessType: string) => {
    handleStripeConnect({
      state: JSON.stringify({
        ...getValues(),
        projectId,
        storeCode,
        bookingId
      }),
      businessType,
      clientID: get(projectQuery, 'findProject.stripeConnectClientId')
    })
  }

  const curriedHandleRegistrationWithStripe = curry(
    handleRegistrationWithStripe
  )

  if (updateBookingStatusLoading || projectQueryLoading)
    return <LoadingScreen />

  if (projectQuery?.findProject.disableLocalzPayBookingMutations) {
    return <RegistrationDisabled />
  }

  if (verificationStatus === 'verified')
    return (
      <RegisterScenario
        title={'Are you sure you want to accept the booking invitation?'}
      >
        Confirming allows you to use mobile payment for your event.
        <Button marginTop="major-4" onClick={handleRegistration}>
          Yes, please
        </Button>
      </RegisterScenario>
    )

  return (
    <Page.Content
      breakpoint="widescreen"
      display="flex"
      flexDirection="column"
      alignItems="center"
      fontFamily="Helvetica Neue"
    >
      <Card.Card maxWidth="720px" marginTop="major-3" padding="major-6">
        <Card.Header>
          <Card.Title
            color="primary"
            fontFamily="Futura"
            fontSize="19px"
            fontWeight="bold"
          >
            You're nearly set to go
          </Card.Title>
        </Card.Header>
        <Card.Content
          display="flex"
          flexDirection="column"
          marginBottom="major-2"
        >
          <form>
            <GraphQLErrors
              errors={[
                {
                  loading: updateBookingStatusLoading,
                  error: updateBookingStatusError
                },
                {
                  loading: getSiteLoading,
                  error: getSiteError
                }
              ]}
            />
            {getSiteLoading && <Spinner size="medium" />}
            {!getSiteLoading && !getSiteError && (
              <>
                <Box
                  marginBottom="major-3"
                  fontFamily="Futura"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  {start && siteData?.findSite?.timezone && (
                    <Box>
                      <Text use="strong">
                        Booking Date:{' '}
                        {formatDate({
                          date: start,
                          timezone: siteData.findSite.timezone
                        })}
                      </Text>
                    </Box>
                  )}
                  <Box>
                    <Text use="strong">Store ID: {storeCode}</Text>
                  </Box>
                  <Box>
                    <Text use="strong">{siteData.findSite.name}</Text>
                  </Box>
                </Box>
                <Box marginBottom="major-3" fontSize="14px" color="#757575">
                  You will need to set up an account to accept cashless payments
                  on the day of your Bunnings sausage sizzle. Bunnings uses
                  Stripe as our payment solution for cashless payments.
                </Box>
                <Box>
                  <Label
                    primaryText="Name of your Community Group"
                    secondaryText="(This will be shown to customers making purchases)"
                    htmlFor="vendorName"
                  />
                  <Controller
                    control={control}
                    id="vendorName"
                    name="vendorName"
                    as={Input}
                    autoComplete="off"
                    placeholder="Community group name"
                    marginTop="major-1"
                    marginBottom="major-3"
                    disabled={!!vendorName}
                    state={
                      get(errors, 'vendorName.message') ? 'danger' : undefined
                    }
                  />

                  <Label
                    primaryText="Sub-division name"
                    htmlFor="alternativeName"
                  />
                  <Controller
                    id="alternativeName"
                    control={control}
                    name="alternativeName"
                    as={Input}
                    autoComplete="off"
                    placeholder="Sub-division name"
                    marginTop="major-1"
                    marginBottom="major-5"
                    disabled={updateBookingStatusLoading}
                    state={
                      get(errors, 'alternativeName.message')
                        ? 'danger'
                        : undefined
                    }
                  />

                  <StripeRegistration
                    handleRegistration={async (businessType) => {
                      await trigger().then((valid) => {
                        if (valid)
                          return curriedHandleRegistrationWithStripe(
                            businessType
                          )
                      })
                    }}
                  />
                </Box>
              </>
            )}
          </form>
          <Privacy />
        </Card.Content>
      </Card.Card>
    </Page.Content>
  )
}
export default RegisterDetails
