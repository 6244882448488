import React from 'react'
import { useParams } from 'react-router-dom'
import { styled } from 'fannypack'
import { getPrivacyLinkByStoreCode } from '../../utils/project'

const Container = styled.div`
  margin-top: 2rem;
  font-size: 0.75rem;
  color: #757575;
`
const Link = styled.a`
  margin-top: 1rem;
  margin-left: 0.25rem;
  font-size: 0.75rem;
  text-decoration: none;
  &:focus {
    outline: none;
  }
`

export const Privacy = () => {
  const { storeCode } = useParams<{ storeCode: string }>()
  const link = getPrivacyLinkByStoreCode(storeCode)
  return (
    <Container>
      Bunnings collects and manages your personal information in accordance with
      our
      <Link href={link} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </Link>
      .
    </Container>
  )
}
export default Privacy
