import React, { useEffect, useCallback, useState } from 'react'
import { styled, Spinner } from 'fannypack'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import * as sdk from '../sdk'
import { LOCAL_STORAGE_JWT } from '../constants'
import { sendSentryError } from '../utils/sentry'
import { useMerchantContext } from '../containers/MerchantContext'

interface Item {
  name: string
  quantity: number
  price: number
  total: number
}

const Container = styled.div`
  flex: 1;
  padding: 0.5rem 1rem;
`
const Title = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
`
const Card = styled.div`
  border-radius: 8px;
  border: solid 1px rgba(151, 151, 151, 0.26);
  background-color: #ffffff;
  padding: 1.5rem;
`
const Label = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
`
const Total = styled.div`
  text-align: center;
  color: #757575;
  font-weight: bold;
  font-size: 1.75rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`
const DateTime = styled.div`
  color: #757575;
  font-weight: 500;
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1.1875rem;
`

const SummaryList = styled.ul`
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
`

const SummaryItem = styled.li`
  color: #757575;
`

function Summary({
  netTurnover,
  items
}: {
  netTurnover: number
  items: Item[]
}) {
  return (
    <>
      <Total>{`$${(netTurnover / 100).toFixed(2)}`}</Total>

      <SummaryList>
        {items.map((item) => {
          return (
            <SummaryItem key={item.name}>
              {item.quantity} x {item.name}, Subtotal:{' '}
              {`$${(item.total / 100).toFixed(2)}`}
            </SummaryItem>
          )
        })}
      </SummaryList>
    </>
  )
}

const SalesSummary = () => {
  const { storeCode } = useParams<{ storeCode: string }>()
  const { setShowError } = useMerchantContext()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [netTurnover, setNetTurnover] = useState<number>(0)
  const [items, setItems] = useState<Item[]>([])

  const getBalance = useCallback(async () => {
    try {
      const token = localStorage.getItem(LOCAL_STORAGE_JWT)!
      const res = await sdk.getBookingWithSummary({ token })

      setNetTurnover(get(res, 'data.findBooking.summary.netTurnover'))
      setItems(get(res, 'data.findBooking.summary.items'))

      setIsLoading(false)
    } catch (e: any) {
      setShowError!(true)
      setIsLoading(false)
      sendSentryError(e, {
        desc: 'Failed to get balance',
        storeCode
      })
    }
  }, [setShowError, storeCode])
  useEffect(() => {
    getBalance()
  }, [getBalance])

  return (
    <Container>
      <Title>Sales summary</Title>
      <Card>
        <Label>Today's total:</Label>

        {isLoading ? (
          <Spinner data-testid="sales-summary-spinner" />
        ) : (
          <Summary netTurnover={netTurnover} items={items} />
        )}
        <DateTime>{dayjs().format('D MMM YYYY, h:mm a')}</DateTime>
      </Card>
    </Container>
  )
}

export default SalesSummary
