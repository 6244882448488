import React from 'react'
import { styled } from 'fannypack'
import { useParams } from 'react-router-dom'
import { find } from 'lodash'
import OrderCard from '../orders/OrderCard'
import { useMerchantContext } from '../../containers/MerchantContext'

const Container = styled.div`
  margin-top: 0.75rem;
  flex: 1 1 auto;
  overflow: auto;
  height: 0;
`

const OrderDetailsContent = () => {
  const { orders } = useMerchantContext()
  const { orderNumber } = useParams<{ orderNumber: string }>()

  return (
    <Container>
      <OrderCard isOrderDetails order={find(orders, { orderNumber })!} />
    </Container>
  )
}

export default OrderDetailsContent
