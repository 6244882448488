import React, { ReactNode } from 'react'
import { Card, Button, Text } from 'fannypack'
import { Spinner } from 'fannypack'

interface Props {
  helpText: string | ReactNode
  buttonLabel: string
  loading?: boolean
  onRegister: () => any
}

export function Register({
  helpText,
  buttonLabel,
  onRegister,
  loading
}: Props) {
  return (
    <Card marginTop="major-2" border>
      <Text fontSize="14px">{helpText}</Text>
      <Button
        fontFamily="Futura"
        marginTop="major-2"
        height="50px"
        width="250px"
        palette="primary"
        color="textOnPrimary"
        onClick={onRegister}
      >
        {loading ? <Spinner color="white" /> : `${buttonLabel}`}
      </Button>
    </Card>
  )
}

export default Register
