import { filter } from 'lodash'
import dayjs from 'dayjs'
import { TOrder, TSite } from '../types'
export const getTodayOrders = ({
  orders,
  site
}: {
  orders: TOrder[]
  site: TSite
}) =>
  filter(
    orders,
    ({ orderDate }) =>
      dayjs(orderDate).isAfter(dayjs().startOf('day')) &&
      dayjs(orderDate).isBefore(dayjs().endOf('day'))
  )
